<template>
  <div class="mHeader" :style="topNavBg">
    <div class="main w588">
      <div class="logo">
        <img src="@/assets/images/logo.png" alt="" />
      </div>
      <div class="list">
        <div class="icon-list" @click="listShow = !listShow">
          <img src="@/assets/images/mobileImgs/list.png" alt="" />
        </div>
        <ul class="listNav" v-show="listShow">
          <li :class="{ listActive: isActive == 0 }" @click="linkC(0)">
            <router-link to="/">首页</router-link>
          </li>
          <li :class="{ listActive: isActive == 1 }" @click="linkC(1)">
            <router-link to="/business">业务方向</router-link>
          </li>
          <li :class="{ listActive: isActive == 2 }" @click="linkC(2)">
            <router-link to="/about">关于凡佶</router-link>
          </li>
          <li :class="{ listActive: isActive == 3 }" @click="linkC(3)">
            <router-link to="/news">新闻资讯</router-link>
          </li>
          <li :class="{ listActive: isActive == 4 }" @click="linkC(4)">
            <router-link to="/join">加入我们</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "mHeader",
  setup() {
    let isActive = ref(0);
    const topNavBg = ref({ backgroundColor: "" });
    const listShow = ref(false);
    return {
      isActive,
      topNavBg,
      listShow,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
    // 点击页面空白处事件监听
    document.onclick = (ev) => {
      if (ev.y > 400 || ev.x < 285) return (this.listShow = false);
    };
  },
  watch: {
    "$route.name": {
      handler(val) {
        switch (val) {
          case "Home":
            this.isActive = 0;
            break;
          case "Business":
            this.isActive = 1;
            break;
          case "About":
            this.isActive = 2;
            break;
          case "News":
            this.isActive = 3;
            break;
          case "Join":
            this.isActive = 4;
            break;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 滚动页面时触发导航变色
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 设置背景颜色的透明度
      if (scrollTop) {
        this.topNavBg.backgroundColor = `rgba(19, 23, 130,
	        	${scrollTop / (scrollTop + 80)})`;
      } else if (scrollTop === 0) {
        this.topNavBg.backgroundColor = "transparent"; // 设置回到顶部时，背景颜色为透明
      }
    },
    onLayoutResize() {
      this.$store.commit("SET_ismobile", document.body.clientWidth < 992);
    },
    // 滚动之前重置
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    linkC(val) {
      this.isActive = val;
      this.listShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.mHeader {
  background: rgba($color: #000, $alpha: 0);
  position: fixed;
  padding: 30px 0 20px;
  z-index: 999;
  width: -webkit-fill-available;

  .main {
    display: flex;
    justify-content: space-between;
    width: 658px;

    .logo {
      width: 160px;

      img {
        width: 100%;
      }
    }
    .list {
      position: relative;
      padding-top: 20px;
      box-sizing: border-box;
      .icon-list {
        width: 30px;
        img {
          width: 100%;
        }
      }
      .listNav {
        position: absolute;
        width: 300px;
        height: 468px;
        background-color: #fff;
        padding: 63px 65px 64px 64px;
        box-sizing: border-box;
        right: 0;
        top: 60px;
        .listActive {
          a {
            color: #166df7;
          }
          img {
            color: #166df7;
          }
        }
        li {
          display: flex;
          justify-content: space-between;
          margin-bottom: 47px;
          a {
            font-size: 32px;
            font-weight: 600;
            color: #333;
          }
          img {
            width: 14px;
          }
        }
      }
    }
  }
}
</style>
