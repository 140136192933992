<template>
  <div class="footer">
    <div class="friendly-link">
      <div class="w1200">
        <span>友情链接：</span>
        <a target="_blank" href="https://www.zju.edu.cn/">浙江大学</a> |
        <a target="_blank" href="https://www.hdu.edu.cn/">杭州电子科技大学</a> |
        <a target="_blank" href="http://www.wzu.edu.cn/">温州大学</a> |
        <a target="_blank" href="https://www.hznu.edu.cn/">杭州师范大学</a> |
        <a target="_blank" href="https://www.mtrhz.com.cn/">杭港地铁</a> |
        <a target="_blank" href="http://www.tailiah.com/">泰力电器</a> |
        <a target="_blank" href="http://www.erongdu.com/">融都科技</a> |
        <a target="_blank" href="http://www.luntek.com.cn/">朗迅科技</a> |
        <a
          target="_blank"
          href="http://www.zjtys.com.cn/zjty/view/home/index.html"
          >泰源科技</a
        >
      </div>
    </div>
    <div class="company">
      <div class="w1200 con">
        <div>
          <div class="name">
            <img src="../assets/images/flogo.png" /><span
              >杭州凡佶互连科技有限公司</span
            >
          </div>
          <p>
            <span
              >杭州凡佶互连科技有限公司成立于2019年，坐落于美丽的西子湖畔。公司源自浙大，由浙大智能软件研究室应晶教授团队提供技术支撑的高级科技型公司，致力于大数据、人工智能等应用领域的产学研成果转化。公司已与多个国家级的科研院所建立了良好的合作关系，目前已有多个科研项目在有序进行，并在科研成果转换方面取得了积极的进展。<br />根据业务发展，公司已成立凡佶互连科技北京分公司，积极组织团队参与国家信创产业的相关领域，推动中国数字经济与智慧社会的进步与发展。</span
            >
          </p>
          <dl>
            <dd><span>联系电话：</span><em>0571-86361560</em></dd>
            <dd><span>电子邮箱：</span><em>hr_c@hzfjtek.com</em></dd>
            <dd>
              <span>杭州总部：</span
              ><em>杭州市余杭区五常街道向往街368号易谷创新园1幢716室</em>
            </dd>
          </dl>
        </div>
        <!--        <img class="map" src="../assets/images/map.png" />-->
        <div id="FJMap"></div>
      </div>
    </div>

    <div class="icp">
      Copyright&nbsp;©2020-2050&nbsp;&nbsp;杭州凡佶互连科技有限公司版权所有&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;浙ICP备2021023792号
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  setup() {
    const map = ref(null); // 地图
    const markers = ref([1]); // 点标记marker
    const markersPosition = ref([120.016509, 30.286732]); // 点标记
    return {
      map,
      markers,
      markersPosition,
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "749338d7e3cbbfbcbb478cdfd67d4ee2", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Weather", "AMap.ElasticMarker"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("FJMap", {
            //设置地图容器id
            enableHighAccuracy: true, // 高精度定位
            viewMode: "3D", //是否为3D地图模式
            zoom: 16, //初始化地图级别
            center: this.markersPosition, //初始化地图中心点位置
          });
          let marker = new AMap.Marker({
            map: this.map,
            position: this.markersPosition,
            offset: new AMap.Pixel(-30, -10),
            title: "杭州凡佶互连科技有限公司",
          });
          // 将 markers 添加到地图
          this.markers.push(marker);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  background: #242b31;
  color: #f8f9f9;

  .friendly-link {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #3c404c;
    box-shadow: 0 1px 0 0 rgba(60, 64, 76, 0.75);

    a {
      color: #f8f9f9;
      padding: 0 10px;

      &:hover {
        color: #fff;
      }
    }
  }

  .company {
    background: #242b31;
    margin: 23px 0 40px;

    .con {
      display: flex;
      justify-content: space-between;
    }

    .name {
      display: flex;
      align-items: center;

      img {
        width: 88px;
        margin-right: 16px;
      }

      span {
        font-size: 18px;
        font-weight: 600;
      }
    }

    p {
      line-height: 1.8;
      color: rgba(255, 255, 255, 0.7);
      margin-top: 11px;
      width: 770px;

      span {
        // display: block;
        // transform: scale(0.9);
        // margin-left: -40px;
      }
    }

    dl {
      color: rgba(255, 255, 255, 0.7);

      dd {
        margin-top: 15px;
        display: block;
        transform: scale(0.9);
        margin-left: -40px;
      }
    }

    #FJMap {
      width: 350px;
      height: 206px;
      margin-top: 60px;
    }
  }

  .icp {
    background: #20252a;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
  }
}
</style>
