<template>
  <mHeader />
  <slot name="mContent">index</slot>
  <mFooter />
</template>
<script>
import mHeader from "./mHeader.vue";
import mFooter from "./mFooter.vue";
export default {
  components: { mHeader, mFooter },
};
</script>
