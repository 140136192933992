<template>
  <div class="header" :style="topNavBg">
    <div class="main w1200">
      <div class="logo"><img src="@/assets/images/logo.png" /></div>
      <ul class="nav" v-if="!ismobile">
        <li><router-link to="/">首页</router-link></li>
        <li><router-link to="/business">业务方向</router-link></li>
        <li><router-link to="/about">关于凡佶</router-link></li>
        <li><router-link to="/news">新闻资讯</router-link></li>
        <li><router-link to="/join">加入我们</router-link></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      topNavBg: {
        backgroundColor: "",
      },
    };
  },
  computed: {
    ismobile() {
      return this.$store.state.global.ismobile;
    },
  },
  created() {
    this.onLayoutResize();
    window.addEventListener("resize", this.onLayoutResize);
  },
  mounted() {
    // this.$store.commit("SET_ismobile", document.body.clientWidth < 992)
    console.log("ismobile", this.$store.state.global.ismobile);
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  },

  methods: {
    // 滚动页面时触发导航变色
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 设置背景颜色的透明度
      if (scrollTop) {
        this.topNavBg.backgroundColor = `rgba(19, 23, 130,
	        	${scrollTop / (scrollTop + 80)})`;
      } else if (scrollTop === 0) {
        this.topNavBg.backgroundColor = "transparent"; // 设置回到顶部时，背景颜色为透明
      }
    },
    onLayoutResize() {
      this.$store.commit("SET_ismobile", document.body.clientWidth < 992);
    },
    // 滚动之前重置
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  background: rgba($color: #000, $alpha: 0);
  height: 68px;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 9;
  border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
  }
  .logo {
    width: 98px;
    height: 48px;
    img {
      width: 100%;
    }
  }
  .nav {
    display: flex;
    li {
      margin-left: 92px;
    }
    a {
      color: rgba($color: #fff, $alpha: 0.5);
      font-size: 18px;
      &.router-link-active {
        color: #fff;
      }
    }
  }
}
</style>
