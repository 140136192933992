<template>
  <div class="mFooter">
    <!--  友情链接-->
    <div class="friendshipLink">
      <div class="w588" style="overflow: scroll">
        <span>友情链接：</span>
        <a target="_blank" href="https://www.zju.edu.cn/">浙江大学</a> |
        <a target="_blank" href="https://www.hdu.edu.cn/">杭州电子科技大学</a> |
        <a target="_blank" href="http://www.wzu.edu.cn/">温州大学</a> |
        <a target="_blank" href="https://www.hznu.edu.cn/">杭州师范大学</a>
      </div>
    </div>
    <!--    公司简介-->
    <div class="company">
      <div class="content w588">
        <!--        标题-->
        <div class="title">
          <img src="../../assets/images/flogo.png" alt="" />
          <span>杭州凡佶互连科技有限公司</span>
        </div>
        <!--        内容-->
        <div class="info">
          <div class="info-text">
            <p>
              杭州凡佶互连科技有限公司成立于2019年，坐落于美丽的西子湖畔。公司源自浙大，由浙大智能软件研究室应晶教授团队提供技术支撑的高级科技型公司，致力于大数据、人工智能等应用领域的产学研成果转化。
            </p>
            <p>
              公司已与多个国家级的科研院所建立了良好的合作关系，目前已有多个科研项目在有序进行，并在科研成果转换方面取得了积极的进展。
            </p>
            <p>
              根据业务发展，公司已成立凡佶互连科技北京分公司，积极组织团队参与国家信创产业的相关领域，推动中国数字经济与智慧社会的进步与发展。
            </p>
          </div>
          <div class="contact">
            <p>联系电话：0571-86361560</p>
            <p>电子邮箱：hr_c@hzfjtek.com</p>
            <p>杭州总部：杭州市余杭区五常街道向往街368号易谷创新园1幢716室</p>
          </div>
        </div>
        <!--        地图-->
        <!--        <div class="map">-->
        <!--          <img class="mMAP" src="../../assets/images/map.png" alt="" />-->
        <!--        </div>-->
        <div id="FJMap"></div>
      </div>
    </div>

    <!--    备案号-->
    <div class="icp">
      Copyright ©2020-2050 杭州凡佶互连科技有限公司版权所有 浙ICP备2021023792号
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "mFooter",
  setup() {
    const map = ref(null); // 地图
    const markers = ref([1]); // 点标记marker
    const markersPosition = ref([120.016509, 30.286732]); // 点标记
    return {
      map,
      markers,
      markersPosition,
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "749338d7e3cbbfbcbb478cdfd67d4ee2", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Weather", "AMap.ElasticMarker"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("FJMap", {
            //设置地图容器id
            enableHighAccuracy: true, // 高精度定位
            viewMode: true, //是否为3D地图模式
            zoom: 16, //初始化地图级别
            center: this.markersPosition, //初始化地图中心点位置
          });
          let marker = new AMap.Marker({
            map: this.map,
            position: this.markersPosition,
            offset: new AMap.Pixel(-30, -10),
            title: "杭州凡佶互连科技有限公司",
          });
          // 将 markers 添加到地图
          this.markers.push(marker);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.mFooter {
  background: #242b31;
  color: #f8f9f9;

  //友情链接
  .friendshipLink {
    height: 48px;
    line-height: 48px;
    border-bottom: 1px solid #3c404c;
    box-shadow: 0px 1px 0px 0px rgba(60, 64, 76, 0.75);

    a {
      color: #f8f9f9;

      &:hover {
        color: #fff;
      }
    }
  }

  //公司简介
  .company {
    .content {
      .title {
        display: flex;
        line-height: 50px;
        margin: 30px 0;

        img {
          width: 117px;
          margin-right: 21px;
        }

        span {
          font-size: 24px;
          font-weight: 600;
        }
      }

      .info {
        p {
          font-size: 20px;
          line-height: 36px;
          opacity: 0.7;
        }

        .info-text {
        }

        .contact {
          margin: 42px 0 47px;
        }
      }

      #FJMap {
        margin-bottom: 65px;
        height: 280px;
      }
    }
  }

  .icp {
    height: 72px;
    line-height: 48px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    opacity: 0.5;
  }
}
</style>
